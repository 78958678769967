<template>
    <Modal
        :id="id"
        :required="false"
        :close="true"
        :show="show"
        class="contact"
    >
        <template #default>
            <ul class="contact__list">
                <li v-for="item in items" class="contact__item">
                    <div class="equal-small-2">{{ item }}</div>
                    <div class="d-flex justify-content-center contact__actions">
                        <div class="d-flex flex-column">
                            <FormButton
                                class="l-button--secondary l-button--icon-only contact__button"
                                @click="handleMailto(item)"
                                data-bs-toggle="modal"
                                :data-bs-target="`#${id}`"
                            >
                                <IconsMail />
                            </FormButton>
                            <span class="equal-small-3 contact__label">
                                {{ t('contact.email.write') }}
                            </span>
                        </div>
                        <div class="d-flex flex-column">
                            <FormButton
                                class="l-button--secondary l-button--icon-only contact__button"
                                @click="handleCopy(item)"
                                data-bs-toggle="modal"
                                :data-bs-target="`#${id}`"
                            >
                                <IconsCopy />
                            </FormButton>
                            <span class="equal-small-3 contact__label">
                                {{ t('contact.email.copy') }}
                            </span>
                        </div>
                    </div>
                </li>
            </ul>
        </template>
    </Modal>
</template>

<script setup>
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const router = useRouter();

const props = defineProps({
    id: {
        type: String,
        default: 'contact-email',
    },
    show: {
        type: Boolean,
        default: false,
    },
    items: {
        type: Array,
        default: () => [],
    },
});

function handleMailto(value) {
    window.location.href = 'mailto:' + value;
}

function handleCopy(value) {
    navigator.clipboard.writeText(value);
    router.replace({ query: { alert: 'emailCopied' } });
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/variables.scss';
@import '@/node_modules/bootstrap/scss/mixins';

.contact {
    :deep(.modal-content) {
        width: auto;
        margin: auto;
        flex-direction: column-reverse;
    }

    :deep(.layout__col) {
        margin: auto;
    }

    :deep(.modal-close) {
        margin: 10px auto 0 auto !important;
    }

    &__list {
        list-style: none;
        padding: 0;
        margin: 0;

        & > *:not(:last-child) {
            margin-bottom: 20px;
        }
    }

    &__item {
        text-align: center;

        &:not(:first-child) {
            margin-top: 25px;
            padding-top: 25px;
            border-top: 1px solid $color-dark-01;
        }
    }

    &__actions {
        margin-top: 15px;

        & > *:not(:last-child) {
            margin-right: 15px;
        }
    }

    &__button {
        margin: auto;
    }

    &__label {
        color: #999999;
        margin-top: 10px;
        text-align: center;
    }
}
</style>
