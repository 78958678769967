<template>
    <svg
        viewBox="0 0 18 21"
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
    >
        <path
            d="M16,3 C17.104,3 18,3.896 18,5 L18,19 C18,20.104 17.104,21 16,21 L5,21 C3.896,21 3,20.104 3,19 L3,5 C3,3.896 3.896,3 5,3 L16,3 Z M16,5 L5,5 L5,19 L16,19 L16,5 Z M12,0 C13.105,0 14,0.895 14,2 L2,2 L2,16 C0.895,16 0,15.105 0,14 L0,2 C0,0.9 0.9,0 2,0 L12,0 Z"
        ></path>
    </svg>
</template>

<script setup></script>

<style lang="scss" scoped></style>
